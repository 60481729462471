import { getFullName } from '../../../shared/helpers/dataUtils';

export default {
  name: 'KnSubjectsAutocomplete',
  components: {},
  mixins: [],
  props: {
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: [Array, String],
      default: null,
    },
    value: {
      type: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    isMandatory: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    selectedTeacher: {
      type: Object,
      default: null,
    },
    subjectsSelectedGroup: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      innerValue: null,
    };
  },
  computed: {
    colsWithoutLabel() {
      return this.showLabel
        ? this.$vuetify.breakpoint.mdAndUp
          ? '8'
          : '12'
        : '12';
    },
    subjectsOfTeacher() {
      return this.selectedTeacher && this.subjectsSelectedGroup.length
        ? this.subjectsSelectedGroup.filter(
            (s) => s.profesor.id === this.selectedTeacher.id
          )
        : [];
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    emitClose(id) {
      // console.log('close chip', id);
      this.$emit('onChipClose', id);
    },
    checkAssignement(subjectId) {
      console.log('Subject Id', subjectId);
      console.log('Materias del maestro', this.subjectsOfTeacher);

      return this.subjectsOfTeacher.find((s) => s.materia.id === subjectId)
        ? 'Asignado'
        : 'Disponible';
    },
    getOwner(subjectId) {
      const subjectTeacher = this.subjectsSelectedGroup.length
        ? this.subjectsSelectedGroup.find((s) => s.materia.id === subjectId)
        : null;
      const isSelected =
        subjectTeacher && this.selectedTeacher
          ? subjectTeacher.profesor.id === this.selectedTeacher.id
          : false;
      const assignedTeacherName = subjectTeacher
        ? subjectTeacher.profesor.datos_personales
          ? `${isSelected ? 'Actual - ' : ''}${getFullName(
              subjectTeacher.profesor.datos_personales
            )}`
          : `${isSelected ? 'Actual - ' : ''}Sin datos personales`
        : null;
      return assignedTeacherName;
    },
  },
};
